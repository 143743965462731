import * as React from 'react';
import { Loader } from './components/Loader';
import to from 'await-to-js';
import { getVideoAspectRatio } from './store/containerDimensions/selectors';
import { PlayerMode } from './components/types/defaultPropTypes';
import { Ratio } from '@voomly/ui/player-deps';
import { PlayerContainerFromFileId } from './PlayerContainerFromFileId';
import { VideoItemsContainerProvider } from './components/VideoItems/VideoItemsContainerContext';
import { IPlayerVideo } from './types/video';
import { IPlayerTemplate, IPlayerWithVideo, IRatio } from './types/player';
import { IFunnelsRaw } from './types/funnels';
import { fetchPublicInfoFunnel } from './api/public/funnels';
import { autofixFunnel } from './autofixers/autofixFunnel';
import { DetachableStoreProviderForPlayer } from './contexts/DetachableStoreProviderForPlayer';
import { PlayerComponentsProvider } from './components/VideoItems/contextProviders/PlayerComponentsProvider';
import { IPlaybackSettings } from './PlayerContainer';

interface IProps {
  ratio: IRatio;
  funnelId: string;
  onLoaded?: (playerConfig: IPlayerTemplate, file: IPlayerVideo) => void;
  isRememberDismissEnabled: boolean;
  extraContent?: React.ReactNode;
}

interface IState {
  funnel?: IFunnelsRaw;
  currentFunnelNodeIndex?: number;
  loadingError?: string;
  playbackSettings: IPlaybackSettings;
}

export class FunnelsPlayer extends React.PureComponent<IProps, IState> {
  private forceAspectRatio: number | undefined;

  public state: IState = {
    playbackSettings: {
      currentTime: 0,
    },
  };

  public componentDidMount() {
    this.fetchFunnel();
  }

  private async fetchFunnel() {
    const { funnelId } = this.props;
    this.setState({
      loadingError: undefined,
      funnel: undefined,
    });

    const [err, response] = await to(fetchPublicInfoFunnel(funnelId));

    if (response) {
      let currentFunnelNodeIndex = 0;

      if (typeof response.startNodeId !== 'undefined') {
        const index = response.nodes.findIndex(
          (node) => node.id === response.startNodeId
        );

        if (index > -1) currentFunnelNodeIndex = index;
      }

      this.setState({
        funnel: autofixFunnel(response),
        currentFunnelNodeIndex,
      });

      return;
    }

    this.setState({
      loadingError: err?.message ?? 'Unable to start the funnel',
    });
  }

  private onNavigateToOtherNodeRequest = (
    nodeId: string,
    timestamp?: number
  ) => {
    this.navigateToNodeId(nodeId, timestamp);
  };

  private navigateToNodeId(nodeId: string, timestamp?: number) {
    const { funnel } = this.state;
    if (!funnel) return;
    const newIndex = funnel.nodes.findIndex((node) => node.id === nodeId);

    if (newIndex === -1) {
      console.log('Answer led nowhere, do nothing');
      return;
    }

    this.setState({
      playbackSettings: {
        currentTime: timestamp || 0,
        forceAutoPlay: true,
        forceAspectRatio: this.forceAspectRatio,
      },
      currentFunnelNodeIndex: newIndex,
    });
  }

  // This ensures player won't change its size when user changes the video
  // Player always stays at the size of the first video
  private handleVideoLoaded = (file: IPlayerVideo) => {
    // That's not the first video in the funnel - player dimensions should stay as the first video requested
    if (this.forceAspectRatio) {
      return;
    }

    // Yes, forceAspectRatio lives outside of React lifecycle (not in state nor props).
    // Apparently player goes crazy if you try to switch playbackSettings during initialization
    // steps; we lock aspectRatio so other videos in the funnel plays in the same player's dimensions,
    // so we force the next video to have these dimensions (without touching the current)
    this.forceAspectRatio = getVideoAspectRatio(file);

    this.props.onLoaded?.(this.state.funnel!.playerConfig, file);
  };

  public render() {
    const { funnel, loadingError, currentFunnelNodeIndex, playbackSettings } =
      this.state;
    const { ratio } = this.props;

    if (funnel && typeof currentFunnelNodeIndex !== 'undefined') {
      const currentNode = funnel.nodes[currentFunnelNodeIndex];

      const playerConfig: IPlayerWithVideo = {
        ...funnel.playerConfig,
        playerTemplateId: funnel.playerTemplateId,
        videoId: currentNode.videoId,
        thumbnail: funnel.thumbnail,
        timelineItems: currentNode.timelineItems,
        thumbnailItems: funnel.thumbnailItems,
        shareVideo: {
          allowComments: false,
          logoInHeader: 'voomlyLogo',
          customLogoUrl: undefined,
          pageTheme: 'systemTheme',
        },
        ...currentNode.player,
      };

      return (
        <VideoItemsContainerProvider>
          <PlayerComponentsProvider>
            <DetachableStoreProviderForPlayer
              timelineItems={currentNode.timelineItems}
              thumbnailItems={playerConfig.thumbnailItems}
            >
              <PlayerContainerFromFileId
                ratio={ratio}
                fileId={currentNode.videoId}
                playerMode={PlayerMode.NORMAL}
                playbackSettings={playbackSettings}
                onNavigateToOtherNodeRequest={this.onNavigateToOtherNodeRequest}
                onLoaded={this.handleVideoLoaded}
                playerConfig={playerConfig}
                isRememberDismissEnabled={this.props.isRememberDismissEnabled}
                extraContent={this.props.extraContent}
                funnelId={funnel?.id}
                currentFunnelNodeId={currentNode.id}
              />
            </DetachableStoreProviderForPlayer>
          </PlayerComponentsProvider>
        </VideoItemsContainerProvider>
      );
    }

    if (loadingError) {
      return <h3>Error: {loadingError}</h3>;
    }

    return (
      <Ratio ratio={ratio}>
        <Loader />
      </Ratio>
    );
  }
}
