import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
import { OptimizedImage } from '@voomly/ui/player-deps';
import { IPlayerRootReducerShape } from '../../store/rootPlayerStore';
import { getThumbnailCustomVideo } from '../../store/thumbnail/selectors';
import { IDefaultPropTypes, PlayerMode } from '../types/defaultPropTypes';
import { VideoLoop } from './VideoLoop';
import styles from './thumbnail.module.sass';

type IProps = IDefaultPropTypes & ConnectedProps<typeof hoc>;

// NOTE: the different version of Thumbnail is present
// at <Unlock> component
export class Thumbnail extends React.PureComponent<IProps> {
  private renderCustomThumbnail() {
    const { thumbnail } = this.props;
    return thumbnail?.type === 'customImage'
      ? this.renderPreviewImage(thumbnail.url)
      : null;
  }

  private renderFrameThumbnail() {
    const { thumbnail } = this.props;
    return thumbnail?.type === 'frameFromVideo'
      ? this.renderPreviewImage(thumbnail.url)
      : null;
  }

  private renderPreviewImage(src: string) {
    if (!src) {
      console.error('Thumbnail renderPreviewImage: no src provided');
      return null;
    }

    const className = cx(styles.image, styles.frameImage);

    if (src.indexOf('blob:') === 0) {
      return <img className={className} src={src} alt="Video preview" />;
    }

    return (
      <OptimizedImage
        className={className}
        url={src}
        size="auto"
        alt="Video preview"
        disableFadeIn={this.props.playerMode === PlayerMode.THUMBNAIL_EDITOR}
      />
    );
  }

  private renderLoopThumbnail() {
    const { thumbnail, file, customVideo } = this.props;

    if (thumbnail?.type !== 'videoLoop') {
      return;
    }

    let videoUrl = file.url;
    if (customVideo && thumbnail.customVideoId) {
      videoUrl = customVideo.url;
    }

    if (!videoUrl) {
      return;
    }

    return (
      <VideoLoop
        fromTime={thumbnail.fromTime}
        toTime={thumbnail.toTime}
        videoUrl={videoUrl}
        firstFrameUrl={thumbnail.firstFrameUrl}
        paused={this.props.playRequested}
        showBlur={this.props.playRequested}
      />
    );
  }

  public render() {
    const { thumbnail, file, isVisible, showOnlyFileThumb } = this.props;
    if (!isVisible) {
      return null;
    }

    return (
      <div className={styles.root}>
        {(!thumbnail || showOnlyFileThumb) &&
          this.renderPreviewImage(file.thumbnailUrl)}

        {!showOnlyFileThumb && (
          <>
            {this.renderCustomThumbnail()}
            {this.renderFrameThumbnail()}
            {this.renderLoopThumbnail()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: IPlayerRootReducerShape,
  props: IDefaultPropTypes
) => {
  const isStartNode =
    state.sourceConfiguration.current?.playerConfig.funnelPlayerAddition
      ?.isStartNode;

  return {
    videoWidth: state.dimensions.containerDimensions.width,
    thumbnail: state.thumbnail.thumbnail,
    isVisible:
      state.thumbnail.isVisible ||
      props.playerMode === PlayerMode.THUMBNAIL_EDITOR,
    customVideo: getThumbnailCustomVideo(state),
    showOnlyFileThumb:
      isStartNode === false || props.playerMode === PlayerMode.TIMELINE_EDITOR,
    playRequested: state.videoState.isPlayRequested,
  };
};
const hoc = connect(mapStateToProps);

// tslint:disable-next-line:max-classes-per-file
class ThumbnailWrapper extends React.PureComponent<IProps> {
  public render() {
    const { thumbnail } = this.props;

    return (
      <Thumbnail
        {...this.props}
        key={(() => {
          if (!thumbnail) {
            return '-';
          }

          if (thumbnail.type === 'videoLoop') {
            return `${thumbnail.fromTime}-${thumbnail.toTime}`;
          } else {
            return thumbnail.url;
          }
        })()}
      />
    );
  }
}

export default hoc(ThumbnailWrapper);
