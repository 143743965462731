import React, { useEffect, useMemo, useRef, useState } from 'react';
import to from 'await-to-js';
import {
  ICallbacks as IOriginalCallbacks,
  IPlaybackSettings,
  IPlayerContainerProps,
  ISourceConfiguration as IOriginalSourceConfiguration,
  PlayerContainer,
} from './PlayerContainer';
import { RequestCancellation } from '@voomly/utils';
import { Loader } from './components/Loader';
import { Ratio } from '@voomly/ui/player-deps';
import { IPlayerWithVideo, IRatio } from './types/player';
import { IPlayerVideo } from './types/video';
import { getPublicFileRequest } from './api/embedVideo';
import { AxiosError, AxiosResponse } from 'axios';
import { PlayerMode } from './components/types/defaultPropTypes';
import { MissedVideo } from './components/MissedVideo/MissedVideo';

// We do something with this ISourceConfiguration to get IOriginalSourceConfiguration,
// that's the whole point of this Class. Used in PlayerConfigForm & Funnels (both editor and user's playback)
interface ISourceConfiguration {
  fileId: string;
  playerConfig: IPlayerWithVideo;
  ratio?: IRatio;
  extraContent?: React.ReactNode;

  // Use this only to load them into player's store
  funnelId?: string;
  currentFunnelNodeId?: string;

  playerMode: PlayerMode;
  playbackSettings: IPlaybackSettings;
}

interface ICallbacks extends IOriginalCallbacks {
  onLoaded: (file: IPlayerVideo) => void;
}

export const PlayerContainerFromFileId: React.FC<
  ISourceConfiguration &
    Partial<ICallbacks> &
    Omit<IPlayerContainerProps, keyof IOriginalSourceConfiguration>
> = ({
  playerExternalAPI,
  fileId,
  onLoaded,
  onChangeDimensions,
  playerConfig,
  ratio,
  playerMode,
  playbackSettings,
  setResetPlayerFunction,
  onNavigateToOtherNodeRequest,
  isRememberDismissEnabled,
  onVideoFinished,
  extraContent,
  onTimeChange,
  funnelId,
  currentFunnelNodeId,
}) => {
  const [isVideoMissed, setIsVideoMissed] = useState(false);

  const requestCancellation = useRef(new RequestCancellation());
  const [updatedPart, setUpdatedPart] = useState<
    | {
        file: IPlayerVideo;
        player: IPlayerWithVideo;
        playbackSettings: IPlaybackSettings;
        currentFunnelNodeId: string | undefined;
      }
    | undefined
  >(undefined);

  const playConfigRef = useRef({
    player: playerConfig,
    playbackSettings,
    currentFunnelNodeId,
  });
  useEffect(() => {
    playConfigRef.current = {
      player: playerConfig,
      playbackSettings,
      currentFunnelNodeId,
    };
  }, [currentFunnelNodeId, playbackSettings, playerConfig]);

  useEffect(() => {
    const requestCancel = requestCancellation.current;
    const currentPlayerConfig = playConfigRef.current;

    const cb = async () => {
      const [err, response] = await to<AxiosResponse<IPlayerVideo>, AxiosError>(
        getPublicFileRequest(fileId, requestCancel.getCancelToken())
      );
      const file: IPlayerVideo | undefined = response?.data;
      const error = err;

      if (file) {
        setUpdatedPart({ file, ...currentPlayerConfig });

        onLoaded?.(file);
      } else if (!requestCancellation.current.isCancel(error)) {
        if (error?.response?.status === 404) {
          setIsVideoMissed(true);
        } else {
          alert('Uh oh, ' + error!.message);
        }
      }
    };

    cb();

    return () => {
      requestCancel.cancelPreviousRequest();
    };
  }, [fileId, onLoaded, playerConfig]);

  // Real-time player update; if player + file updated together, the other effect should trigger
  useEffect(() => {
    if (!updatedPart) {
      return;
    }

    const fileIsTheSame = updatedPart.file.id === fileId;

    if (
      (playerConfig !== updatedPart.player ||
        playbackSettings !== updatedPart.playbackSettings ||
        currentFunnelNodeId !== updatedPart.currentFunnelNodeId) &&
      fileIsTheSame
    ) {
      setUpdatedPart({
        file: updatedPart.file,
        player: playerConfig,
        currentFunnelNodeId,
        playbackSettings,
      });
    }
  }, [
    currentFunnelNodeId,
    fileId,
    playbackSettings,
    playerConfig,
    updatedPart,
  ]);

  const passThroughProps: IPlayerContainerProps | undefined = useMemo(
    () =>
      updatedPart
        ? {
            ratio,
            playerExternalAPI,
            onTimeChange: onTimeChange,
            onChangeDimensions,
            playbackSettings: updatedPart.playbackSettings,
            playerMode: playerMode,
            setResetPlayerFunction: setResetPlayerFunction,
            playerConfig: updatedPart.player,
            file: updatedPart.file,
            onNavigateToOtherNodeRequest: onNavigateToOtherNodeRequest,
            isRememberDismissEnabled: isRememberDismissEnabled,
            onVideoFinished: onVideoFinished,
            extraContent: extraContent,
            funnelId,
            currentFunnelNodeId,
          }
        : undefined,
    [
      ratio,
      playerExternalAPI,
      currentFunnelNodeId,
      extraContent,
      funnelId,
      isRememberDismissEnabled,
      onChangeDimensions,
      onNavigateToOtherNodeRequest,
      onTimeChange,
      onVideoFinished,
      playerMode,
      setResetPlayerFunction,
      updatedPart,
    ]
  );

  if (!passThroughProps) {
    return isVideoMissed ? (
      <MissedVideo caption="Video not found" />
    ) : (
      <Ratio ratio={ratio || 1.7777}>
        {playerMode !== PlayerMode.MINI_PLAYER && (
          <Loader
            color={
              playerConfig.appearance.bgColorEnabled
                ? playerConfig.appearance.bgColor
                : undefined
            }
          />
        )}
      </Ratio>
    );
  }

  return <PlayerContainer {...passThroughProps} />;
};
