import { IPlayerRootReducerShape } from '../rootPlayerStore';
import { createSelector } from 'reselect';
import { shallowEqual } from 'react-redux';
import { PlayerMode } from '../../components/types/defaultPropTypes';

const EMPTY_ARRAY = [];

export const getChangingVideo = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.next;
};

export const getPlaybackSettings = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.playbackSettings;
};

export const getPlayerConfig = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.playerConfig;
};

export const getFile = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.file;
};

export type IConfigIds = Exclude<ReturnType<typeof getConfigIds>, undefined>;
export const getConfigIds = (state: IPlayerRootReducerShape) => {
  const current = state.sourceConfiguration.current;

  const ids = current
    ? {
        videoId: current.file.id,
        funnelId: current.funnelId,
        nodeId: current.currentFunnelNodeId,
        spotlightId: current.spotlightId,
      }
    : undefined;

  const hashId = (
    ids ? [ids.videoId, ids.funnelId, ids.nodeId, ids.spotlightId] : []
  ).join('|');

  // Hash is needed to route events to the correct session in saga
  return (
    ids && {
      ids,
      id: hashId,
    }
  );
};

export const getPlayerMode = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.playerMode;
};

export const getFunnelId = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.funnelId;
};

export const getCurrentFunnelNodeId = (state: IPlayerRootReducerShape) => {
  return state.sourceConfiguration.current?.currentFunnelNodeId;
};

const getSourceConfiguration = (state: IPlayerRootReducerShape) =>
  state.sourceConfiguration;

export const getCurrentSourceConfiguration = (state: IPlayerRootReducerShape) =>
  state.sourceConfiguration.current;

export const getTimelineItems = createSelector(
  getSourceConfiguration,
  (sourceConfiguration) => {
    return (
      sourceConfiguration.current?.playerConfig.timelineItems || EMPTY_ARRAY
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
      maxSize: 2,
    },
  }
);

export const getThumbnailItems = createSelector(
  getSourceConfiguration,
  (sourceConfiguration) => {
    return (
      sourceConfiguration.current?.playerConfig.thumbnailItems || EMPTY_ARRAY
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
      maxSize: 2,
    },
  }
);

export const getIsVideoChanging = (state: IPlayerRootReducerShape) => {
  return Boolean(state.sourceConfiguration.next);
};

export const getIsAnalyticsTrackingAllowed = (
  state: IPlayerRootReducerShape
) => {
  const sourceConfig = getCurrentSourceConfiguration(state);
  return Boolean(
    sourceConfig &&
      !sourceConfig.withoutStatisticsTracking &&
      sourceConfig.playerMode === PlayerMode.NORMAL
  );
};
